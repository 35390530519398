import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { withRouter } from 'react-router-dom';

import { Container, Modal, Button } from 'react-bootstrap';

import * as appUtils from './utils';

import PageWaiting from './pages/PageWaiting';
import PageLogin from './pages/PageLogin';
import PageApplication from './pages/PageApplication';

class App extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.frontend_settings = appUtils.settingsJSON();
    this.pageapplication_ref = React.createRef();
    this.state = {
      // one of appStateEnum
      appState: appUtils.appStateEnum.appStateInitial,
      showLoginAlert: false,
      frontend_settings: this.frontend_settings,
      // websocket
      ws: null,
      wsactive: false,
      wsconnected: false,
      timerConnectorLoop: null,
      // login
      user_name: '',
      user_is_admin: false,
      user_level: 0,
      // data
      hasdata: true,
      // misc
      dlg_pull_show_flag: false,
      dlg_pull_result: ''
    };
  }

  componentWillUnmount = () => {
    this.mounted = false;
    if (this.state.timerConnectorLoop) clearInterval(this.state.timerConnectorLoop);
  };

  componentDidMount = () => {
    console.clear();
    document.title = appUtils.appName();
    this.connectorLoop();
  };

  render = () => {
    var appvisible =
      this.state.appState === appUtils.appStateEnum.appStateLoggedInSelector && this.state.hasdata === true;
    return (
      <Container>
        <Modal show={this.state.dlg_pull_show_flag} onHide={this.dlg_pull_close} centered>
          <Modal.Header closeButton>
            <Modal.Title>git pull result</Modal.Title>
          </Modal.Header>
          <Modal.Body><pre>{this.state.dlg_pull_result}</pre></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.dlg_pull_close}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.appState === appUtils.appStateEnum.appStateInitial && <PageWaiting />}
        {this.state.appState === appUtils.appStateEnum.appStateSent && <PageWaiting />}
        {this.state.appState === appUtils.appStateEnum.appStateShowLogin && (
          <PageLogin showLoginAlert={this.state.showLoginAlert} login_func={this.doLogin} />
        )}
        <div className={appvisible ? 'div-visible' : 'div-hidden'}>
          <PageApplication
            user_name={this.state.user_name}
            user_is_admin={this.state.user_is_admin}
            user_level={this.state.user_level}
            frontend_settings={this.state.frontend_settings}
            logout_func={this.doLogout}
            send_func={this.msgSend}
            history={this.props.history}
            ref={this.pageapplication_ref}
          />
        </div>
      </Container>
    );
  };

  ///////////////////
  // WebSocket basics
  ///////////////////

  connectorLoop = () => {
    const intervalId = setInterval(() => {
      if (!this.state.wsconnected) {
        this.doConnectWS();
      }
    }, 2000);
    this.setState({ timerConnectorLoop: intervalId });
  };

  msgSend = (json) => {
    try {
      if (this.state.wsconnected) {
        this.state.ws.send(JSON.stringify(json));
      }
    } catch (exception) {
      this.doCloseWS();
    }
  };

  doCloseWS = () => {
    if (this.state.wsactive && this.state.ws != null) {
      this.state.ws.close();
    }
    this.setState((state) => ({
      ws: null,
      wsactive: false,
      wsconnected: false,
      hasdata: false
    }));
  };

  doConnectWS = () => {
    try {
      if (this.state.wsactive) {
        this.doCloseWS();
      }
      this.setState((state) => ({ wsactive: true }));
      let ws = new WebSocket(appUtils.wsBaseURL());
      this.setState((state) => ({ ws: ws }));
      ws.onopen = (event) => {
        this.setState((state) => ({ wsconnected: true }));
        var currentToken = localStorage['token'] || '-';
        var json = { logintoken: currentToken };
        this.msgSend(json);
        this.setState((state) => ({ appState: appUtils.appStateEnum.appStateSent }));
      };
      ws.onmessage = (event) => {
        this.doProcessData(event.data);
      };
      ws.onerror = (event) => {
        this.doCloseWS();
      };
      ws.onclose = (event) => {
        this.doCloseWS();
      };
    } catch (exception) {
      this.doCloseWS();
    }
  };

  /////////////////////////////
  // WebSocket additional logic
  /////////////////////////////

  doProcessData = (data) => {
    var j = JSON.parse(data);
    // 'loginresponse' verb
    if ('loginresponse' in j) {
      //console.log(JSON.stringify(j, null, 4));
      var token = j['loginresponse'];
      //var un = j['un'];
      var user_name = j['name'];
      var user_is_admin = j['is_admin'];
      var user_level = j['level'];
      localStorage['token'] = token;
      if (token === '-') {
        this.setState((state) => ({
          appState: appUtils.appStateEnum.appStateShowLogin,
          user_name: user_name,
          user_is_admin: user_is_admin,
          user_level: user_level,
          un: '',
          pw: ''
        }));
      } else {
        this.setState((state) => ({
          appState: appUtils.appStateEnum.appStateLoggedInSelector,
          showLoginAlert: false,
          user_name: user_name,
          user_is_admin: user_is_admin,
          user_level: user_level
        }));
      }
      return;
    }
    // data: contains array of big data dump or hand picked items when they change
    if ('data' in j) {
      var datajson = j['data'];
      this.pageapplication_ref.current.received_new_data(datajson);
      this.setState({ hasdata: true });
      return;
    }
    // porcelain: getting git status on request
    if ('porcelain' in j) {
      var porcelainjson = j['porcelain'];
      this.pageapplication_ref.current.received_new_porcelain(porcelainjson);
      return;
    }
    // indexjson: getting git status on request
    if ('indexjson' in j) {
      var indexjson = j['indexjson'];
      this.pageapplication_ref.current.received_new_indexjson(indexjson);
      return;
    }
    // release-report: getting release report after git commands
    if ('releasereport' in j) {
      var rrjson = j['releasereport'];
      this.pageapplication_ref.current.received_new_release_report(rrjson);
      return;
    }
    // edits: getting list of edit fields from .tiler.json
    if ('edits' in j) {
      //var rrjson = j['releasereport'];
      //this.pageapplication_ref.current.received_new_release_report(rrjson);
      this.pageapplication_ref.current.received_edits(j);
      return;
    }
    if ('pullresult' in j) {
      var pullresult = j['pullresult'];
      this.setState({ dlg_pull_result: pullresult, dlg_pull_show_flag: true });
    }
    // something unknown arrived
    console.log('ws data unknown: ' + data);
  };

  dlg_pull_close = () => {
    this.setState({ dlg_pull_show_flag: false });
  };

  /////////////////
  // Login callback
  /////////////////

  doLogin = (un, pw) => {
    let json = { loginun: un, pw: pw };
    this.msgSend(json);
    this.setState({ appState: appUtils.appStateEnum.appStateInitial, showLoginAlert: true });
  };

  doLogout = () => {
    var json = { logout: 'x' };
    this.msgSend(json);
  };
}

export default withRouter(App);
